import gql from "graphql-tag";

export const SIGNIN_USER_MUTATION = gql`
  mutation TokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      user {
        id
        username
        authgroupsoperatorsSet {
          edges {
            node {
              id
              authGroup {
                id
                authgroupspagepermissionsSet {
                  edges {
                    node {
                      id
                      pagePermission {
                        id
                        url
                        name
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const CALL_ME = gql`
  query {
    me {
      username
      id
      authgroupsoperatorsSet {
        edges {
          node {
            id
            operator {
              id
              username
            }
            authGroup {
              id
              name
              authgroupspagepermissionsSet {
                edges {
                  node {
                    id
                    pagePermission {
                      id
                      name
                      url
                    }
                  }
                }
              }
            }
            farmer {
              id
              name
            }
          }
        }
      }
    }
  }
`;

// CREATE_FARMER_MUTATION

export const CREATE_FARMER_MUTATION = gql`
  mutation createFarmer($input: FarmerInput!) {
    createFarmer(input: $input) {
      farmer {
        id
        name
      }
    }
  }
`;

<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="8">
          <b-card-group>
            <b-card no-body class="p-4">
              <b-card-body>
                <b-form @submit.prevent="confirm">
                  <h1>Login</h1>
                  <p class="text-muted">Sign In to your account</p>
                  <b-input-group class="mb-3">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-user"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="email"
                      type="email"
                      class="form-control"
                      placeholder="UserID"
                      autocomplete="username email"
                      required
                      :state="validation"
                    />
                    <b-form-invalid-feedback :state="validation">
                      {{ validMsg }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                  <b-input-group class="mb-4">
                    <b-input-group-prepend>
                      <b-input-group-text>
                        <i class="icon-lock"></i>
                      </b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="password"
                      type="password"
                      class="form-control"
                      placeholder="Password"
                      autocomplete="current-password"
                      required
                      :state="validation"
                    />
                    <b-form-invalid-feedback :state="validation">
                      {{ validMsg }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                  <b-row>
                    <b-col>
                      <b-button type="submit" size="sm" variant="primary"
                        >Login</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>
<script>
import { GC_USER_ID, GC_AUTH_TOKEN } from "../../components/auth-settings";
import { SIGNIN_USER_MUTATION } from "../../components/login-auth";
import { MY_FARMER_QUERY } from "../../components/current-farmer-query";

const CREDENTIAL_ERROR = "有効な認証情報を入力してください";

export default {
  data() {
    return {
      email: "",
      password: "",
      validMsg: "",
      validation: null,
      farmer: null,
    };
  },
  methods: {
    confirm: function () {
      const { email, password } = this.$data;
      this.$apollo
        .mutate({
          mutation: SIGNIN_USER_MUTATION,
          variables: {
            email,
            password,
          },
        })
        .then((result) => {
          const id = result.data.tokenAuth.user.id;
          const token = result.data.tokenAuth.token;
          this.saveUserData(id, token);
          this.getFarmer();
        })
        .catch((error) => {
          if (error.message.search(CREDENTIAL_ERROR) != -1) {
            this.setValid(CREDENTIAL_ERROR);
          } else {
            alert(error);
            console.error(error);
          }
        });
    },
    getFarmer() {
      this.$apollo
        .query({
          query: MY_FARMER_QUERY,
        })
        .then((result) => {
          const myFarmers = result.data.me.farmersoperatorsSet.edges;
          if (myFarmers.length == 0) {
            // TODO: 農家選択画面に飛ばす
            this.setValid("ユーザーIDまたはパスワードが違います");
            this.clearUserData();
          } else {
            this.$router.push({ path: "/camerapicturespage" });
          }
        });
    },
    saveUserData(id, token) {
      localStorage.setItem(GC_USER_ID, id);
      localStorage.setItem(GC_AUTH_TOKEN, token);

      this.$root.$data.userId = localStorage.getItem(GC_USER_ID);
    },
    clearUserData() {
      localStorage.removeItem(GC_USER_ID);
      localStorage.removeItem(GC_AUTH_TOKEN);
    },
    setValid(msg) {
      this.validMsg = msg;
      this.validation = false;
    },
  },
};
</script>
